let events = [
    {
        title: "international.workshop.title",
        content: "international.workshop.content",
        overlayText: '<span style="color: #E52A30">W</span>ORKSHOP',
        slides: [
            {
                image: require("@/assets/images/International/workshop1.jpg")
            },
            {
                image: require("@/assets/images/International/workshop2.jpg")
            },
            {
                image: require("@/assets/images/International/workshop3.jpg")
            }
        ],
        hoverimage: require("@/assets/images/unicorn.jpg")
    },
    {
        title: "international.exchange.title",
        content: "international.exchange.content",
        overlayText: '<span style="color: #E52A30">E</span>XCHANGE',
        slides : [
            {
                image: require("@/assets/images/International/exchange1.jpg")
            },
            {
                image: require("@/assets/images/International/exchange2.jpg")
            },
            {
                image: require("@/assets/images/International/exchange3.jpg")
            }
        ],
        hoverimage: require("@/assets/images/unicorn.jpg")
    },
    {
        title: "international.congres.title",
        content: "international.congres.content",
        overlayText: '<span style="color: #E52A30">C</span>ONGRESS',
        slides : [
            {
                image: require("@/assets/images/International/congress1.jpg")
            },
        ],
        hoverimage: require("@/assets/images/unicorn.jpg")
    }
]

export { events };