<template>
    <div class = "international-hero-card-container">
        <div class = "international-hero-card">
            <img :src="logoEestec">
            <p class = "international-hero-card-content">{{$t('cards.internationalCard.content')}}</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            logoEestec: require("@/assets/images/International/EESTEC.png")
        } 
    }
}
</script>
<style lang="scss" scoped>
.international-hero-card-container {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 350px;
    padding-bottom: 20px;   
}
.international-hero-card {
    width: 35%;
    height: 100%;
    @extend %card-shadow;
    border: 1px solid grey;
    border-radius: 30px;
    background-color: white;
    word-wrap: break-word;
    padding-top: 2.5%;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 10px;

    & > img{
        height: 40%;
        margin-bottom: 6%;
    }
}
.international-hero-card-title {
    font-size: 20px;
    font-family: $primary-font;
    margin: 20px;
}
.international-hero-card-content{
    font-size: 15px;
}
</style>