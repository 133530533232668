<template>
  <div id="international">
    <ScrollDownBar class="is-hidden-mobile" :content="content.international" />
    <InternationalMap class="is-hidden-mobile"/>
    <InternationalMapMobile class="is-hidden-desktop"/>
    <div id = "international-events">
      <h1> {{$t("international.eventsHeader")}} </h1>
      <RowSectionInternational v-for="(event, index) in events" :key="index" :event = "event" :isReversed="isEven(index)"></RowSectionInternational>
    </div>
    <InternationalSlider :slides = "internationalSlides"> </InternationalSlider>
  </div>
</template>

<script>

import RowSectionInternational from '@/components/cards/RowSectionInternational'
import InternationalMap from '@/components/heroes/InternationalMapHero'
import InternationalMapMobile from '@/components/heroes/InternationalMapMobile'
import InternationalSlider from '@/components/sliders/InternationalSlider'
import ScrollDownBar from '@/components/global-components/ScrollDownBar'

import {events} from "@/assets/resources/events"
import {internationalSlides} from "@/assets/resources/internationalSlides"

import {content} from "@/assets/resources/scrollDownBarContent"

export default {
  components:{
    RowSectionInternational,
    InternationalMap,
    InternationalMapMobile,
    InternationalSlider,
    ScrollDownBar
  },
  data () {
      return {
          events,
          internationalSlides,
          content
      }
  },
  methods: {
      isEven(index) {
          return index % 2 === 0;
      }
    }    
  
}
</script>

<style lang="scss" scoped>
#international {
  padding-top: 116px;
}

#international-events {
  padding-top: 100px;
}

@media only screen and (max-width: 600px) {
  #international {
    padding-top: 70px;
  }

  #international-events {
    padding-top: 50px;

    & > h1 {
      font-size: 24px;
    }
  }
}
</style>