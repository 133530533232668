<template>
    <div id = "international-map-hero">
        <parallax :speedFactor="1">
            <img src="../../assets/images/International/EESTECmap.png">
        </parallax>
        <div class="internationalCards">
            <InternationalCard />
        </div>
        <div class = "red">
        </div> 
    </div>
</template>

<script>

import InternationalCard from '@/components/cards/InternationalCard'
import Parallax from 'vue-parallaxy'

export default {
    components:{
        InternationalCard,
        Parallax
    },
}
</script>

<style lang="scss">
#international-map-hero{
    position:relative;
    margin-bottom: 10%;
    height: 80vh;

    .international-map{
    height: 80vh;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .red{
        margin-top: -10px;
        height: 150px;
        background-size: cover;
        background-color: $primary-color;
    }

    .internationalCards{
        overflow: hidden;
        background-color: transparent;
        position: absolute;  
        bottom: -100px;
    }

    .Masthead {
        min-height: unset;
        height: 80vh !important;
    }

    .Masthead__image {
        height: 180%;
    }
}
</style>