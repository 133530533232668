<template>
    <div id = "home-hero-mobile">
        <div class="image" >
            <img src='../../assets/images/International/EESTECmap.png' >
        </div>
        <div class = "icon-card-container">
            <div class = "icon-card">
                <img src = "@/assets/images/International/EESTEC.png" alt="" class="home-hero-mobile-card-image" />
                <p class = "home-hero-mobile-card-content">{{$t('cards.internationalCard.content')}}</p>
            </div>
        </div>
    </div> 
</template>

<script>

import HeroCard from '@/components/cards/HomeCard'

export default {
    components:{
        HeroCard
    }
    
}
</script>

<style lang="scss" scoped>
#home-hero-mobile{
    position: relative;
    overflow: hidden;
}

.homeImage{
    background-size: cover;
    margin-bottom: 0px;
}


.aboutCards{
    overflow: hidden;
    background-color: transparent;
  
}

.icon-card {
    width: 85%;
    padding-left: 5%;
    padding-right: 5%;
    
}

.icon-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;

}

.home-hero-mobile-card-title {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    font-size: 16px;   
}

.home-hero-mobile-card-content {
    
    align-items: center;
    width: 100%;
    font-size: 14px;
    text-align: center;   
}


.home-hero-mobile-card-image {
    height: 80px;
    margin-top: 3px;
}

</style>