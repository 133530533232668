<template>
  <div id="international-slider">
    <h1>{{$t("cards.internationalSlider.title")}}</h1>
    <flickity ref="flickity" class="carousel-main" :options="flickityOptions">
        <div class="carousel-cell" v-for="(slide, index) in slides" :key="index">
          <div><b-icon :icon="slide.icon" size="is-large"></b-icon> </div>
          <h1 class="title">{{$t(slide.title)}}</h1>
          <p>{{$t(slide.text)}}</p>
        </div>
      </flickity>
  </div>
</template>

<script>

import Flickity from 'vue-flickity'

export default {
    components:{
      Flickity
    },
    props : [
      'slides'
    ],
    data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: true,
        pageDots: true,
        setGallerySize: false,
        arrowShape: { 
                      x0: 10,
                      x1: 60, y1: 50,
                      x2: 70, y2: 40,
                      x3: 30
                    }
      }
    }
  },
}
</script>

<style lang="scss">
#international-slider {
  i {
    color: $primary-color;
  }

  & {
  background-color: $secondary-color;
  margin-bottom: 20px;
  padding-bottom: 40px;
  padding-left: 8%;
  padding-right: 8%;
  }

  .title{
    font-size: 24px;
    word-break: normal; // ca sa nu mai fie textul de verticala
    flex-basis: 100%; // ca sa fie doar un elem pe linie
    margin-bottom: 8%;
    margin-top: 5%;
  }

  .carousel-main{
    height: 530px;
  }

  img { 
    margin-top: 30px;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 30px;
  }

  .carousel-cell {
    width: 30%;
    height: 460px;
    width: 400px;
    margin: 50px 50px;
    border: 1px solid grey;
    box-shadow: 5px 5px rgb(177, 176, 176);
    border-radius: 30px;
    background-color: white;
    transition: 1s all;
    font-size: 16px;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }

  .flickity-button {
    background: transparent;
    height: 10vh;
    width: 10vw;
  }
  
  .flickity-button:hover .arrow {
    fill: #000;
  }

  .flickity-prev-next-button:hover {
    background: transparent !important;
  }

  .flickity-prev-next-button .arrow {
    fill: grey;
    transition: 0.3s all;
  }

  .flickity-button:disabled {
    display: none;
  }

  .flickity-prev-next-button.previous {
    left: -10% !important;
  }

  .flickity-prev-next-button.next {
    right: -10% !important;
  }

  @media only screen and (max-width: 600px) {
    .carousel-cell {
      width: 90%;
      font-size: 14px;
    }

    .title {
      font-size: 20px;
    }
  }
}
</style>