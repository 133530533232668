let internationalSlides = [
    {
        title:"cards.internationalSlider.title1",
        image: require("@/assets/images/internationalSlider.png"),
        text:"cards.internationalSlider.content1",
        icon: "account-tie"
    },
    {
        title:"cards.internationalSlider.title2",
        image: require("@/assets/images/internationalSlider.png"),
        text:"cards.internationalSlider.content2",
        icon: "chart-bell-curve-cumulative"
    },
    {
        title:"cards.internationalSlider.title3",
        image: require("@/assets/images/internationalSlider.png"),
        text:"cards.internationalSlider.content3",
        icon: "brush"
    },
    {
        title:"cards.internationalSlider.title4",
        image: require("@/assets/images/internationalSlider.png"),
        text:"cards.internationalSlider.content4",
        icon: "book-search-outline"
    },
    {
        title:"cards.internationalSlider.title5",
        image: require("@/assets/images/internationalSlider.png"),
        text:"cards.internationalSlider.content5",
        icon: "arm-flex-outline"
    },
    {
        title:"cards.internationalSlider.title6",
        image: require("@/assets/images/internationalSlider.png"),
        text:"cards.internationalSlider.content6",
        icon: "desktop-mac"
    },
    {
        title:"cards.internationalSlider.title7",
        image: require("@/assets/images/internationalSlider.png"),
        text:"cards.internationalSlider.content7",
        icon: "account-group-outline"
    },
    {
        title:"cards.internationalSlider.title8",
        image: require("@/assets/images/internationalSlider.png"),
        text:"cards.internationalSlider.content8",
        icon: "teach"
    }
]


export {internationalSlides};